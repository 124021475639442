<template>
	<div class="setting">
		<!-- <v-btn @click="clickPub">clickPub</v-btn> -->
		<v-container>
			<h4 align="center">{{ farm_name }}</h4>
			<v-row align="center" justify="center">
				<v-btn
					icon
					elevation="2"
					outlined
					class="ma-2"
					@click="$router.push('farm')"
				>
					<v-icon> mdi-arrow-left </v-icon>
				</v-btn>
				<h1>{{ green_house_name }}</h1>
			</v-row>
			<h4 align="center">ตั้งค่าโต๊ะปลูก</h4>
		</v-container>
		<v-row no-gutters>
			<v-col md="8" offset-md="2">
				<v-card class="pa-2" rounded>
					<v-card-text>
						ชื่อโต๊ะ
						<v-text-field
							v-model="table_name"
							outlined
						></v-text-field>
					</v-card-text>
					<center>
						<v-btn @click="savetable_rename" color="amber">
							บันทึก
						</v-btn>
					</center>
				</v-card>
			</v-col>
		</v-row>
		<br />
		<v-row no-gutters>
			<v-col md="8" offset-md="2">
				<v-card class="pa-2" rounded>
					<v-card-text>
						<v-container>
							<v-row align="center">
								เปิดใช้งาน
								<v-switch v-model="switch1"></v-switch>
							</v-row>
						</v-container>

						พืชที่ปลูก
						<v-select
							v-model="plant_id"
							:items="plant_list_data"
							outlined
						></v-select>
						วันที่เริ่มปลูก
						<v-dialog
							ref="dialog"
							v-model="modal"
							:return-value.sync="start_date"
							persistent
							width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									:value="computedDateFormattedMomentjs"
									outlined
									readonly
									v-bind="attrs"
									v-on="on"
									locale="thai"
									clearable
									@click:clear="start_date = null"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="start_date"
								scrollable
								locale="th"
							>
								<v-spacer></v-spacer>
								<v-btn
									text
									color="primary"
									@click="modal = false"
								>
									ยกเลิก
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.dialog.save(start_date)"
								>
									ตกลง
								</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-card-text>
					<center>
						<v-btn @click="savecrop" color="amber"> บันทึก </v-btn>
					</center>
				</v-card>
			</v-col>
		</v-row>
		<br />
		<v-row no-gutters>
			<v-col md="8" offset-md="2">
				<v-card class="pa-2" rounded>
					<v-card-text>
						ตั้งเวลาเปิด-ปิด พืชที่ปลูก
						<v-select
							v-model="select_io_id"
							:items="io_id_list"
							outlined
						></v-select>
						<v-row>
							<v-col cols="6">
								<v-dialog
									ref="dialog1"
									v-model="modal1"
									:return-value.sync="time1"
									persistent
									width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="time1"
											label="เวลาเปิด"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-time-picker
										v-if="modal1"
										v-model="time1"
										full-width
										format="24hr"
									>
										<v-spacer></v-spacer>
										<v-btn
											text
											color="primary"
											@click="modal1 = false"
										>
											ยกเลิก
										</v-btn>
										<v-btn
											text
											color="primary"
											@click="$refs.dialog1.save(time1)"
										>
											ตกลง
										</v-btn>
									</v-time-picker>
								</v-dialog>
							</v-col>
							<v-col cols="6">
								<v-dialog
									ref="dialog2"
									v-model="modal2"
									:return-value.sync="time2"
									persistent
									width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="time2"
											label="เวลาปิด"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-time-picker
										v-if="modal2"
										v-model="time2"
										full-width
										format="24hr"
									>
										<v-spacer></v-spacer>
										<v-btn
											text
											color="primary"
											@click="modal2 = false"
										>
											ยกเลิก
										</v-btn>
										<v-btn
											text
											color="primary"
											@click="$refs.dialog2.save(time2)"
										>
											ตกลง
										</v-btn>
									</v-time-picker>
								</v-dialog>
							</v-col>
						</v-row>
						<center>
							<v-btn @click="add_schedule" color="amber">
								เพิ่มรายการ
							</v-btn>
							<v-data-table
								:headers="headers"
								:items="schedule_list"
								class="elevation-1"
							>
								<template v-slot:item.io_id="{ item }">
									Output {{ item.io_id }}
								</template>
								<template v-slot:item.active="{ item }">
									<v-icon
										@click="
											delete_schedule(item.schedule_id)
										"
										left
										color="red"
									>
										{{ icons.mdiDelete }}
									</v-icon>
									<!-- <v-btn @click="add_schedule" color="red"> เพิ่มรายการ </v-btn> -->
								</template>
							</v-data-table>
						</center>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<br />
		<v-row no-gutters>
			<v-col md="8" offset-md="2">
				<v-card class="pa-2" rounded>
					<v-card-text>
						สั่งปิด-เปิด
						<v-row>
							<v-col
								v-for="(output_io, index) in io_id_list_active"
								:key="output_io.io_id"
								sm="3"
								cols="6"
							>
								Output {{ output_io.io_id }}
								<v-switch
									inset
									color="success"
									v-model="output_io.io_open"
									@change="
										OepnIo(
											output_io.io_id,
											output_io.io_open,
											index + 9
										)
									"
								></v-switch>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<br />
		<br />
	</div>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import { mdiAccount, mdiPencil, mdiShareVariant, mdiDelete } from "@mdi/js";
export default {
	name: "setting",
	data: function () {
		return {
			icons: {
				mdiAccount,
				mdiPencil,
				mdiShareVariant,
				mdiDelete,
			},
			farm_name: "",
			green_house_name: "",
			table_name: "",
			plant_id: null,
			plant_list_data: [],
			active: 0,
			switch1: false,
			start_date: format(
				parseISO(new Date().toISOString()),
				"yyyy-MM-dd"
			),
			modal: false,
			headers: [
				{ text: "ช่อง", value: "io_id" },
				{ text: "เวลาเปิด", value: "schedule_start" },
				{ text: "เวลาปิด", value: "schedule_end" },
				{ text: "ลบ", value: "active" },
			],
			schedule_list: [],
			time1: null,
			menu1: false,
			modal1: false,
			time2: null,
			menu2: false,
			modal2: false,
			io_id_list: [
				{
					value: "",
					text: "เลือก Output",
				},
			],
			select_io_id: "",
			io_id_list_active: [],
		};
	},
	created: function () {
		window.scrollTo(0, 0);
		this.farm_name = this.$session.get("farm_data").farm_name;
		this.green_house_name =
			this.$session.get("green_house_item").green_house_name;
		this.table_name = this.$session.get("table_data").table_name;
		this.table_info();
		this.plant_list();
		this.view_schedule();
	},
	computed: {
		computedDateFormattedMomentjs() {
			return this.start_date
				? moment(this.start_date)
						.add(543, "year")
						.locale("th")
						.format("LL")
				: "";
		},
	},

	watch: {},

	methods: {
		savetable_rename() {
			var self = this;
			var config = {
				method: "PUT",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/table_rename",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				data: {
					table_id: this.$session.get("table_data").table_id,
					table_name: this.table_name,
				},
			};
			this.axios(config)
				.then(function (response) {
					console.log(response.data);
					if (response.data.success == 1) {
						self.$swal.fire({
							position: "center",
							icon: "success",
							title: "บันทึกเรียบร้อย",
							showConfirmButton: false,
							timer: 1500,
						});
						// self.$router.push("/farm");
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		savecrop() {
			var self = this;
			var config = {
				method: "post",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/crop",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				data: {
					table_id: this.$session.get("table_data").table_id,
					is_open: 1,
					plant_id: this.plant_id,
					start_date: self.start_date,
				},
			};
			this.axios(config)
				.then(function (response) {
					console.log(response.data);
					if (response.data.success == 1) {
						self.$swal.fire({
							position: "center",
							icon: "success",
							title: "บันทึกเรียบร้อย",
							showConfirmButton: false,
							timer: 1500,
						});
						// self.$router.push("/farm");
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		async plant_list() {
			var self = this;
			var config = {
				method: "get",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/plant_list",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
			};
			await this.axios(config)
				.then(function (response) {
					console.log(response.data);
					if (response.data.success == 1) {
						response.data.data.forEach((element) => {
							self.plant_list_data.push({
								value: element.plant_id,
								text: element.plant_name,
							});
						});
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		async table_info() {
			var self = this;
			var config = {
				method: "get",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/table_info",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				params: { table_id: this.$session.get("table_data").table_id },
			};
			await this.axios(config)
				.then(function (response) {
					console.log(response.data);
					if (response.data.success == 1) {
						if (response.data.data.table_crop.length > 0) {
							self.table_name =
								response.data.data.table_crop[0].table_name;
							self.start_date =
								response.data.data.table_crop[0].start_date;
							self.plant_id =
								response.data.data.table_crop[0].plant_id;
						}
						if (response.data.data.table_environment.length > 0) {
							if (
								response.data.data.table_environment[0]
									.active == 1
							) {
								self.switch1 = true;
							}
						}
						if (response.data.data.io.length > 0) {
							self.io_id_list_active = [];
							response.data.data.io.forEach((element) => {
								if (element.io_type == "o") {
									self.io_id_list.push({
										value: element.io_id,
										text: "Output " + element.io_id,
									});
									self.io_id_list_active.push(element);
								}
							});
						}
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		async view_schedule() {
			var self = this;
			var config = {
				method: "get",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/schedule",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				params: {
					table_id: this.$session.get("table_data").table_id,
				},
			};
			await this.axios(config)
				.then(function (response) {
					console.log(response.data);
					if (response.data.success == 1) {
						self.schedule_list = response.data.data;
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		add_schedule() {
			var self = this;
			if (this.select_io_id == "") {
				this.$swal.fire({
					position: "center",
					icon: "warning",
					title: "กรุณาเลือก Output",
					showConfirmButton: false,
					timer: 1500,
				});
				return;
			} else if (this.time1 == "" || this.time1 == null) {
				this.$swal.fire({
					position: "center",
					icon: "warning",
					title: "กรุณาเลือก เวลาเปิด",
					showConfirmButton: false,
					timer: 1500,
				});
				return;
			} else if (this.time2 == "" || this.time2 == null) {
				this.$swal.fire({
					position: "center",
					icon: "warning",
					title: "กรุณาเลือก เวลาปิด",
					showConfirmButton: false,
					timer: 1500,
				});
				return;
			}
			if (this.time1 == this.time2 || this.time1 > this.time2) {
				this.$swal.fire({
					position: "center",
					icon: "warning",
					title: "ช่วงเวลา เปิด-ปิด ไม่ถูกต้อง",
					showConfirmButton: false,
					timer: 1500,
				});
				return;
			}
			let checkTime = true;

			this.schedule_list.forEach((element) => {
				if (this.select_io_id == element.io_id) {
					if (
						this.time1 <= element.schedule_end &&
						element.schedule_start <= this.time2
					) {
						this.$swal.fire({
							position: "center",
							icon: "warning",
							title: "มีช่วงเวลาทับซ้อน",
							showConfirmButton: false,
							timer: 1500,
						});
						checkTime = false;
						return;
					}
				}
			});
			if (checkTime) {
				var config = {
					method: "put",
					url: "https://cool-agri.com/hydroponic_portal/api/v1/schedule_add",
					headers: {
						Authorization: localStorage.getItem("jwt"),
						"Content-Type": "application/json",
					},
					data: {
						io_id: this.select_io_id,
						schedule_start: this.time1,
						schedule_end: this.time2,
					},
				};
				this.axios(config)
					.then(function (response) {
						console.log(response.data);
						if (response.data.success == 1) {
							self.view_schedule();
							self.$swal.fire({
								position: "center",
								icon: "success",
								title: "บันทึกเรียบร้อย",
								showConfirmButton: false,
								timer: 1500,
							});
						}
						self.UpdateIoMqtt();
					})
					.catch(function (error) {
						console.log(error);
						this.$router.push("/");
					});
			}
		},
		delete_schedule(schedule_id) {
			var self = this;
			self.$swal
				.fire({
					title: "ยืนยันที่จะลบ",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ใช่",
					cancelButtonText: "ไม่",
				})
				.then((result) => {
					if (result.isConfirmed) {
						var config = {
							method: "delete",
							url: "https://cool-agri.com/hydroponic_portal/api/v1/schedule_del",
							headers: {
								Authorization: localStorage.getItem("jwt"),
								"Content-Type": "application/json",
							},
							data: {
								schedule_id: schedule_id,
							},
						};
						this.axios(config)
							.then(function (response) {
								console.log(response.data);
								if (response.data.success == 1) {
									self.view_schedule();
									self.$swal.fire({
										position: "center",
										icon: "success",
										title: "ลบเรียบร้อย",
										showConfirmButton: false,
										timer: 1500,
									});
								}
								self.UpdateIoMqtt();
							})
							.catch(function (error) {
								console.log(error);
								this.$router.push("/");
							});
					}
				});
		},
		OepnIo(io_id, io_open, io_local) {
			var self = this;
			self.$swal
				.fire({
					title: "ยืนยัน",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ใช่",
					cancelButtonText: "ไม่",
				})
				.then((result) => {
					if (result.isConfirmed) {
						var config = {
							method: "put",
							url: "https://cool-agri.com/hydroponic/api/v1/table_io",
							headers: {
								"Content-Type": "application/json",
							},
							data: {
								io_id: io_id,
								io_open: io_open ? 1 : 0,
							},
						};
						this.axios(config)
							.then(function (response) {
								console.log(response.data);
								if (response.data.success == 1) {
									self.table_info();
									self.$swal.fire({
										position: "center",
										icon: "success",
										title: "เรียบร้อย",
										showConfirmButton: false,
										timer: 1500,
									});
								}
								self.OepnIoMqtt(io_id, io_open, io_local);
							})
							.catch(function (error) {
								console.log(error);
								self.$router.push("/");
							});
					} else {
						self.table_info();
					}
				});
		},
		OepnIoMqtt(io_id, io_open, io_local) {
			var self = this;
			var config = {
				method: "post",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/table_io/mqtt",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				data: {
					table_id: self.$session.get("table_data").table_id,
					io_local: io_local,
					io_id: io_id,
					io_status: io_open ? 1 : 0,
				},
			};
			this.axios(config)
				.then(function (response) {
					console.log(response.data);
				})
				.catch(function (error) {
					console.log(error);
					self.$router.push("/");
				});
		},
		UpdateIoMqtt() {
			var self = this;
			var config = {
				method: "post",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/schedule_update/mqtt",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				data: {
					table_id: self.$session.get("table_data").table_id,
				},
			};
			this.axios(config)
				.then(function (response) {
					console.log(response.data);
				})
				.catch(function (error) {
					console.log(error);
					self.$router.push("/");
				});
		},
	},
};
</script>

<style></style>
